import React, { useState } from "react";

import { createSearchParams, useNavigate } from "react-router-dom";

import DestinationPickerInput from "./components/DestinationPickerInput";
import PersonsInput from "./components/PersonsInput";
import DateSingleInput from "./components/DateSingleInput";
import ButtonSubmit from "./components/ButtonSubmit";
import CategoryPickerInput from "./components/CategoryPickerInput";
import SearchInput from "./components/SearchInput";

const HeroSearchForm = () => {
  const navigate = useNavigate();

  const [date, setDate] = useState(undefined);
  const [persons, setPersons] = useState(undefined);
  const [destinationId, setDestinationId] = useState(undefined);
  const [regionId, setRegionId] = useState(undefined);
  const [categoryId, setCategoryId] = useState(undefined);
  const [search, setSearch] = useState(undefined);

  const handleSearch = () => {
    const params = {
      search,
      date,
      persons,
      destinationId,
      regionId,
      categoryId,
    };

    // Clear undefined params
    for (const key in params) {
      if (
        params[key] === undefined ||
        params[key] === null ||
        params[key] === ""
      ) {
        delete params[key];
      }
    }

    navigate({
      pathname: "/search",
      search: createSearchParams(params).toString(),
    });
  };

  const handleChangeDestination = ({ destinationId, regionId }) => {
    setDestinationId(destinationId);
    setRegionId(regionId);
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex flex-col rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700">
        {/* Search Input - Always on top */}
        <div className="w-full">
          <SearchInput onChange={(value) => setSearch(value)} />
        </div>

        {/* Filters - Inline on larger screens, with separators on smaller screens */}
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700 md:flex-row md:divide-y-0 md:space-x-4">
          {/* Destination Picker */}
          <div className="w-full md:py-0 md:flex-1">
            <DestinationPickerInput onChange={handleChangeDestination} />
          </div>

          {/* Category Picker */}
          <div className="w-full md:py-0 md:flex-1">
            <CategoryPickerInput onChange={(value) => setCategoryId(value)} />
          </div>

          {/* Date Picker */}
          <div className="w-full md:py-0 md:flex-1">
            <DateSingleInput onChange={(date) => setDate(date)} />
          </div>

          {/* Persons Input */}
          <div className="w-full md:py-0 md:flex-1">
            <PersonsInput onChange={(data) => setPersons(data)} />
          </div>

          {/* Submit Button */}
          <div className="px-4 py-4 lg:py-0 flex items-center justify-center">
            <ButtonSubmit onClick={handleSearch} />
          </div>
        </div>
      </form>
    );
  };

  return renderForm();
};

export default HeroSearchForm;
