import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import SectionHero from "components/SectionHero/SectionHero";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

import useConfig from "hooks/useConfig";
import usePortal from "hooks/usePortal";

import ListingProductsGrid from "containers/ListingExperiencesPage/ListingProductsGrid";

import moment from "moment";

function SearchPage() {
  const { company } = useConfig();
  const { destinations, categories, getProducts } = usePortal();

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const [pagination, setPagination] = useState({ page: 1, size: 12, total: 0 });

  const [searchParams] = useSearchParams();

  const destinationId = searchParams.get("destinationId");
  const regionId = searchParams.get("regionId");
  const categoryId = searchParams.get("categoryId");
  const date = searchParams.get("date");
  const persons = searchParams.get("persons");
  const search = searchParams.get("search");

  const [filters, setFilters] = useState({});

  const fetchProducts = (pagination) => {
    setLoadingProducts(true);

    const filtersInfo = {
      ...filters,
      date,
      persons,
      search,
      destinationIds: destinationId ? [destinationId] : [],
      regionIds: regionId ? [regionId] : [],
      categoryIds: categoryId ? [categoryId] : [],
    };

    getProducts({
      ...filtersInfo,
      page: pagination.page - 1,
      size: pagination.size,
      fields: ["name", "currency", "profilePhoto", "galleryPhotos"],
      embed: [
        "fromPrice",
        "location",
        "location.region",
        "region.destination",
        "supplierName",
      ],
    }).then((data) => {
      setProducts(data.products);
      setTotalProducts(data.totalProducts);

      setPagination({ ...pagination, total: data.totalProducts });

      setLoadingProducts(false);
    });
  };

  useEffect(() => {
    const initPagination = { ...pagination, page: 1 };
    setPagination(initPagination);
    fetchProducts(initPagination);
  }, [destinationId, regionId, categoryId, date, persons, search, filters]);

  const handleChangePagination = (pagination) => {
    setPagination(pagination);
    fetchProducts(pagination);
  };

  const handleChangeFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  let selectedCategory = categories.find(
    (category) => category._id === categoryId
  );

  let selectedDestination;
  let selectedRegion;

  if (destinationId) {
    selectedDestination = destinations.find(
      (destination) => destination._id === destinationId
    );

    if (selectedDestination && regionId) {
      selectedRegion = selectedDestination.regions.find(
        (region) => region._id === regionId
      );
    }
  } else if (regionId) {
    selectedDestination = destinations.find(
      (destination) =>
        destination.regions.findIndex((region) => region._id === regionId) > -1
    );

    if (selectedDestination) {
      selectedRegion = selectedDestination.regions.find(
        (region) => region._id === regionId
      );
    }
  }

  let heroTitle = "Search";
  let experiencesTitle = "Experiences";
  let heroSubtitle;
  let richTextSubtitle = false;
  let heroDescription;
  let richTextDescription = false;
  let heroPhotos = [];

  if (selectedDestination) {
    if (selectedRegion) {
      heroTitle = `${selectedRegion.name}, ${selectedDestination.title.EN}`;
    } else {
      heroTitle = selectedDestination.title.EN;
    }

    if (selectedDestination.idealFor?.length > 0) {
      heroSubtitle = `Ideal for ${selectedDestination.idealFor.join(", ")}`;
    }

    if (selectedCategory) {
      if (selectedRegion) {
        experiencesTitle = `${selectedCategory.name.EN} in ${selectedRegion.name}`;
      } else {
        experiencesTitle = `${selectedCategory.name.EN} in ${selectedDestination.title.EN}`;
      }
    }

    heroDescription = selectedDestination.content.EN?.substring(0, 500);
    richTextDescription = true;

    heroPhotos = [selectedDestination.coverPhoto].concat(
      selectedDestination.galleryPhotos
    );
  } else if (selectedRegion) {
    heroTitle = selectedRegion.name.EN;
  } else if (selectedCategory) {
    heroTitle = selectedCategory.name.EN;
    heroSubtitle = selectedCategory.subtitle;
    heroDescription = selectedCategory.description?.substring(0, 500);
    heroPhotos = selectedCategory.photos;
  }

  return (
    <div className="nc-SearchPage relative overflow-hidden">
      <Helmet>
        <title>
          {heroTitle} - {company.name}
        </title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <SectionHero
          className="pt-10 pb-0"
          centerTitle
          title={heroTitle}
          subtitle={heroSubtitle}
          richTextSubtitle={richTextSubtitle}
          description={heroDescription}
          photos={heroPhotos}
          richTextDescription={richTextDescription}
        />

        {/* SECTION */}
        <ListingProductsGrid
          heading={experiencesTitle}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {totalProducts} {totalProducts === 1 ? "result" : "results"}
              {date && (
                <>
                  <span className="mx-2">·</span>
                  {moment(date, "YYYY-MM-DD").format("DD MMM")}
                </>
              )}
              {persons && (
                <>
                  <span className="mx-2">·</span>
                  {persons} {persons === "1" ? "person" : "persons"}
                </>
              )}
            </span>
          }
          products={products}
          totalProducts={totalProducts}
          loadingProducts={loadingProducts}
          showFilters
          onChangeFilter={handleChangeFilter}
          sliderStyle="style1"
          pagination={pagination}
          onChangePagination={handleChangePagination}
        />
      </div>
    </div>
  );
}

export default SearchPage;
