import React, { Fragment, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Popover, Transition } from "@headlessui/react";

import NcInputNumber from "components/NcInputNumber/NcInputNumber";

const PersonsInput = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const [value, setValue] = useState(undefined);

  const [searchParams] = useSearchParams();
  const queryPersons = searchParams.get("persons");

  const handleChangeValue = (value) => {
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (Number.isInteger(Number(queryPersons)) && Number(queryPersons) > 0) {
      handleChangeValue(Number(queryPersons));
    }
  }, []);

  return (
    <Popover className="flex relative [ nc-flex-1 ]">
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <i className="las la-user-plus text-3xl"></i>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                {!value ? "Persons" : `${value || ""}`}
              </span>

              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {value
                  ? value > 1
                    ? "Persons"
                    : "Person"
                  : "Number of persons"}
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={value}
                onChange={handleChangeValue}
                min={1}
                label="Number of persons"
                // desc="Number of persons"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PersonsInput;
