import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import Loading from "components/Loading/Loading";

import usePortal from "hooks/usePortal";

import ClearDataButton from "./ClearDataButton";

const DestinationPickerInput = ({
  autoFocus = false,
  onChange,
  placeHolder = "Destination",
  desc = "Select a Destination",
  className = "nc-flex-1.5",
}) => {
  const { destinations, loadingDestinations } = usePortal();

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);

  const [searchParams] = useSearchParams();
  const queryDestinationId = searchParams.get("destinationId");
  const queryRegionId = searchParams.get("regionId");

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }

    showPopover && document.addEventListener("click", eventClickOutsideDiv);

    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event) => {
    if (!containerRef.current) return;

    if (!showPopover || containerRef.current.contains(event.target)) {
      return;
    }

    setShowPopover(false);
  };

  const handleSelectDestination = (destinationId) => {
    const destination = destinations.find((dest) => dest._id === destinationId);

    onChange({ destinationId: destination._id });

    setValue(destination.title.EN);
    setShowPopover(false);
  };

  const handleSelectRegion = (regionId, destinationId) => {
    const destination = destinations.find((dest) => dest._id === destinationId);
    const region = destination.regions.find(
      (region) => region._id === regionId
    );

    onChange({ destinationId: destination._id, regionId: region._id });

    setValue(`${region.name}, ${destination.title.EN}`);
    setShowPopover(false);
  };

  const clearValue = () => {
    setValue("");
    onChange({ destinationId: undefined, regionId: undefined });
  };

  useEffect(() => {
    let defaultDestinationId;
    let defaultRegionId;

    if (queryDestinationId) {
      const defaultDestination = destinations.find(
        (destination) => destination._id === queryDestinationId
      );

      if (defaultDestination) {
        defaultDestinationId = defaultDestination._id;

        if (queryRegionId) {
          const defaultRegion = defaultDestination.regions.find(
            (region) => region._id === queryRegionId
          );

          if (defaultRegion) {
            defaultRegionId = defaultRegion._id;
          }
        }
      }
    } else if (queryRegionId) {
      const defaultRegionDestination = destinations.find(
        (destination) =>
          destination.regions.findIndex(
            (region) => region._id === queryRegionId
          ) > -1
      );

      if (defaultRegionDestination) {
        defaultDestinationId = defaultRegionDestination._id;
        defaultRegionId = queryRegionId;
      }
    }

    if (defaultRegionId) {
      handleSelectRegion(defaultRegionId, defaultDestinationId);
    } else if (defaultDestinationId) {
      handleSelectDestination(defaultDestinationId);
    }
  }, [destinations]);

  const renderDestinations = () => {
    return (
      <>
        {destinations.map((destination) => (
          <div key={destination._id}>
            <h3
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => handleSelectDestination(destination._id)}
            >
              {destination.title.EN}
            </h3>
            <div className="mt-2 ml-4">
              {(destination.regions || []).map((region) => (
                <span
                  onClick={() =>
                    handleSelectRegion(region._id, destination._id)
                  }
                  key={region._id}
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                >
                  <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                    {region.name}
                  </span>
                </span>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-map-marker-alt text-3xl"></i>
        </div>
        <div className="flex-grow">
          {loadingDestinations ? (
            <Loading align="left" />
          ) : (
            <>
              <input
                className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                placeholder={placeHolder}
                value={value}
                autoFocus={showPopover}
                onChange={(e) => setValue(e.currentTarget.value)}
                ref={inputRef}
                readOnly
              />
              <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                <span className="line-clamp-1">
                  {!!value ? placeHolder : desc}
                </span>
              </span>
              {value && showPopover && <ClearDataButton onClick={clearValue} />}
            </>
          )}
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderDestinations()}
        </div>
      )}
    </div>
  );
};

export default DestinationPickerInput;
