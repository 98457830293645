import React, { useState, useRef, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

const SearchInput = ({
  autoFocus = false,
  onChange,
  placeHolder = "Search...",
  className = "nc-flex-1",
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");

  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("search");

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleChange = (inputValue) => {
    setValue(inputValue);
    onChange(inputValue);
  };

  useEffect(() => {
    if (querySearch) {
      handleChange(querySearch);
    }
  }, []);

  return (
    <div className={`relative flex ${className}`}>
      <div
        className={`flex flex-1 relative nc-hero-field-padding flex-shrink-0 items-center space-x-3 focus:outline-none text-left`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <i className="las la-search text-3xl"></i>
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            ref={inputRef}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
